.formualire_section_mobile{
  font-size: smaller;
  text-align: center;
  p{
    margin: 0 1em 0 1em ;
  }

  .button_overlay{
    color: #fff;
    background-color:rgb(180, 170, 203);
    margin: 1.5em 0 1.5em 0;
    border: #b3a8ca 1px solid;
    padding: 0.5em;
  }

  .contact_insta{
    margin: 1.5em 1em 1em 1em;
  }
}



.modal-overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 3em 0 3em;
    background-color:rgba(180, 170, 203,1);
    justify-content: center;
    align-items: center;
    animation: slide 0.6s ease-in-out ;
    z-index: 8;
    
    .cross{
        position:absolute;
        top: 3%;
        left: 88%;
        font-size: 1.7em;

    }
    
    
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;

    animation: slide 1.2s ease-in-out ;
  }

  
  .modal-content h2 {
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .modal-content form label {
    color: #fff;
    /* Styles pour le formulaire */
  }
  
  .modal-content button {
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    
  }

  .button_submit{
    text-align: center;

   
    .input_{
        display: none;
    }

    .button_reset{
        margin-left: 0.1em;
        margin: 1.5em 1.5em 0 1.5em;
        background-color: #b3a8ca;
    }
  }

  @keyframes slide {
    0% {
      transform: translateY(50%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .notif_mobile{
    width:800px;
    height: auto;
    font-size: large;
}

  @keyframes slideR {
    0% {
      transform: translateY(0%);
      opacity: 1;
    }
    100% {
      transform: translateY(50%);
      opacity: 0;
    }
  }

 /* Styles pour la modale fermée */
.closed {
    animation: slideR 0.6s ease-in-out;
  }