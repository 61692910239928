


.container-portfolio-categorie{
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
       

    .container-portfolio-categorie-titleSection{
        text-align: center;
        font-weight: bold;
        font-size: 2.5em;
        text-decoration: underline;
      
        margin: 5em 3em 3em 3em;
    }
    
    .container-portfolio-categorie-description{
        max-width: 80%;
        display: flex;
        max-height: 700px;
        flex-direction: row;
        justify-content: space-around;
        overflow: hidden;
        
      
        

        .picture-description-container{
            max-width: 60%;
            height: auto;
            overflow: hidden;
            transition: all 0.3s ease-out ;
            
            

            .picture-description{
                max-width: 100%;
                max-height:700px;
                object-fit: contain;
                

                
                
                    
            }
            &:hover{
                transform: scale(1.02);   
                cursor: pointer;
                opacity: 0.85;
                
                
            }
            
            
            
            
            

            
        

           
        }

           

        .container-description{
            max-width: 35%;
            height: auto;
            padding-top: 40px;
            padding-right: 30px;
            margin-left: 30px;

            .container-description-bloc{
                margin: auto;
                padding: 20px;
                width:100%;
                
                h2{
                    text-align: center;
                    font-weight: bold;
                    font-size: 1.6em;
                    margin: 0 0 20px 0;
                    
                }
                p{
                    font-weight: 542;
                    font-size: 17px;
                    line-height: 30px;
                    text-align: center;

                }
                
            }
            


           
        }

        
    }

    .picture-containers{

        display: flex;
        flex-direction: row;
        margin-top: 180px;
        overflow: hidden;

        .pictures-containers-left{
            max-width: 50%;
            height: auto;
            display: flex;
            flex-direction: column;
        
            align-items: flex-end;
            .picture-container{
                max-width: 56%;
                height: auto;
                margin: 0 10px 35px 0;
                overflow: hidden;
                transition: all 0.3s ease-out ;

                .picture {
                    max-width: 100%;
                    height: auto;
                    transition: all 0.3s ease-out;
                
                
                }
                &:hover{
                    img{
                        transform: scale(1.1);
                        opacity: 0.85;
                    }
                    
                }
            }
            
        }


        .pictures-containers-right{
            max-width: 50%;
            height: auto;
            display: flex;
            flex-direction: column;

            align-items: flex-start;
            padding-top: 80px;

            
           
            .picture-container{
                max-width: 56%;
                height: auto;
                margin: 0 0 40px 10px;
                overflow: hidden;

                transition: all 0.3s ease-out ;

                .picture {
                    max-width: 100%;
                    height: auto;
                    transition: all 0.3s ease-out;
                
                    
                }
                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                    
                }


            }
        }
    }


}


.ant-image-mask{
    content: "regarder";
}