.print_container{
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title_print{
        padding-top: 3em;
        text-align: center;
        font-weight: bold;
        font-size: 2.5em;
        text-decoration: underline;
        margin: 2em 3em 3em 3em;
    }

    .print_description{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
        height: auto;
        font-size: large;
        font-weight: bold;
        line-height: 25px;
        text-align: center;
        max-width: 60em;
        height: 25em;
        background-color:#b3a8ca;

        padding: 1.8em 1.8em 1.8em 1.8em;

        color: white;

        span{

            font-weight: bolder;
            color:rgb(143, 4, 177);
        }
        
        h3 {
            font-size: larger;
        }

        ul{
            list-style: inside;
            text-align: left;

            li{
                font-size: medium;
                margin-left: 2em;
                span{
                    
                    font-weight: bolder;
                    color:rgb(143, 4, 177);
                }
            }
            
        }

        a{
            &:hover{
                text-decoration: underline;
            }
        }
        .dispo{
            text-align: left;
            font-size: medium;
        }

        .fin{

            font-size: medium;
        }
    
    }



    
    
    
    
    .print_picture{
        margin: 10em 0 3em 0;
        width: 70%;

        .image_print{
            max-width: 500px;
            max-height: 700px;
            &:hover{
                transform: scale(1.02); 
            }
        }    


        .item_list{
            margin-bottom: 8em;
        }
    
    
    }
    
}   
   

@media screen and (min-width: 850px) and (max-width: 1024px) {
    .none_label_print{
        display: none;
    }
  }
    