.navigation{
    
    // max-width: 100%;
    position: fixed;
    z-index:1000;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding: 1px 0 1px 10px;
    

    background-color: white;

    right:0px;
    left:0px;

    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.483);

    

    .navBar{
        display: flex;
        margin-top: 35px;
        margin-right: 35px;
        &:hover {
          color: rgb(184, 126, 148);
          }

        a{
          max-width: 40%;
          height: auto;
          margin:20px;
          color:black;
          list-style-type: none;
          font-size: 1.35em;


          transition: 0.2s;
          cursor: pointer;

          
          .portfolio{
            font-size: 1em;
          }
          
            
          
        }
        
      
        

        
    }
}

.selected {
  color: rgb(184, 126, 148);
}

