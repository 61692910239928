.contactSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 70%;

    margin-bottom: 5em;
    color: white;

    .form-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color:rgba(179, 168, 202, 0.902);
        padding: 3em 1.5em 1.5em 2em;
        

        max-width: 100%;
        height: auto;
    }
    p{
        font-weight: 700;
        font-size: 24px;
        margin: 1px 2px 60px 2px;
    }
    form{
        display: flex;
        flex-direction: column;
        
        width: 70%; 
        margin: 0 auto;

        .label, label{
            margin-top: 15px;
            margin-bottom: 4px;
            font-weight: 800;
            font-size: 18px;
            color: #ffffff;
        }

        .simple-input{
            
            margin-bottom: 1px;
            font-size: 16px;
        
        
        }

        input[type="text"]:focus, textarea:focus {
			border-color: #ffffff;
		}
    
        textarea{
            padding-bottom: 10em;
            font-size: 16px;
            
                  
        }
        .button-bloc{
            display: flex;
            justify-content: center;
            margin-bottom: 50px;
            margin-top: 20px;

            transition: all 3s ease-in;

            input{
                
                background-color: white ;
                color: black;
                font-weight: bold;
                font-size: 16px;
                border: 0 ridge;
                border-radius: 6px;
                padding: 10px 10px 10px 10px;

                &:hover{
                    cursor:pointer;
                    opacity: .8;
                }
            }

        }
    }
            
}


.notif{
    width:800px;
    height: auto;
    font-size: large;
}