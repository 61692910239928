.section_description_mobile{
    background-color: rgba(179, 168, 202,1);
    margin: 4em 2em 0 2em;
    text-align: center;
    padding: 1.5em 1.5em 1.5em 1.5em;
    color: white;

    h3{
        font-weight: bold;
        margin-bottom: 1em;
        
    }

    p{  
        
        font-size: 15px;
        line-height: 23px;

        max-height: 200px; /* Hauteur maximale de la zone de texte */
        overflow: auto; /* Activation du défilement vertical automatique */
        -webkit-overflow-scrolling: touch;
    }
}


.picture-containers_mobile{
    display: flex;
    flex-direction: row;
    overflow: hidden;

    margin-top: 4em;

    .pictures-containers-left_mobile{
        
        max-width: 50%;
        height: auto;
        display: flex;
        flex-direction: column;
    
        align-items: flex-end;

        .picture-container_mobile{
            max-width: 62%;
            height: auto;
            margin: 0 10px 15px 0;
            overflow: hidden;


            .picture_mobile{
                max-width: 100%;
                height: auto;
            }
        }

    }


    .pictures-containers-right_mobile{
        max-width: 50%;
        height: auto;
        display: flex;
        flex-direction: column;

        align-items: flex-start;
        padding-top: 35px;


        .picture-container_mobile{
            max-width: 62%;
            height: auto;
            margin: 0 10px 15px 0;
            overflow: hidden;

            .picture_mobile{
                max-width: 100%;
                height: auto;
            }
        }
    }


}



.stories{
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    animation: zoomOut 0.4s ease-in-out ;
    z-index: 8;
    
    // padding: 5em 1em 5em 1em;
    background-color: rgba(0, 0, 0, 0.2);

    
}


@keyframes zoomOut {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }