/* Styling pour le container du carousel */
.carousel-container {
    background-color: #f2f2f2; /* Couleur de fond gris */
    padding: 10px;
  }
  
  /* Styling pour le titre */
  .carousel-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  /* Styling pour les flèches */
  .carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .carousel-arrow:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .carousel-arrow-left {
    left: 20px;
  }
  
  .carousel-arrow-right {
    right: 20px;
  }
  
  /* Styling pour le wrapper du carousel */
  .carousel-wrapper {
    display: flex;
    overflow-x: hidden;
    margin-bottom: 20px;
    position: relative;
  }
  
  /* Styling pour les images */
  .carousel-image {
    height: 100px; /* Hauteur fixe pour toutes les images */
    max-width: 100%; /* Largeur maximale pour les images en mode paysage */
    object-fit: contain; /* Redimensionne l'image pour s'adapter à la hauteur fixe tout en conservant les proportions */
  }
  