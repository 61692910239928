
.home1{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    
    .fondHome{
        
        display: flex;
        justify-content: center;
        width: 100%;


        div{
            width: 62%;
            margin-top: 8.6em;
            
            img{
                max-width: 100%;
                max-height: 100%;

            }

        }
        
    }


    .titleSection{
        text-align: center;
        font-weight: bold;
        font-size: 2.5em;
        text-decoration: underline;
      
        margin: 4em 3em 3em 3em;
      }
}


