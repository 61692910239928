.aboutMeSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    max-width: 70%;

    .aboutMe{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        background-color:#b3a8ca;


        max-width: 100%;
        height: auto;

        .aboutMePicture{
        width: 25em;
        height: auto;

        clip-path:ellipse(33% 50%);
        margin-top: 50px;

        }
        .aboutMeText{
           
            max-width: 90%;
            height: auto;
            border-radius: 16px;
            padding: 2em;
            margin-left: 2em;
            margin-right: 2em;
            margin-bottom: 2em;

            p{
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                margin: 10px;
                text-align: justify;
                color: rgb(255, 255, 255);
                text-shadow: 1px 0 0 rgba(215, 215, 215,0.5);
            }
        }
    }
}


