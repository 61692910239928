

.print_container_mobile{
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3em;

}


.print_description_container{
    width: 80%;
    height: auto;

    border-radius: 1.5%;
    text-align: center;
    padding: 1.5em;


    .title_print{
        font-size: larger;
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 1.5em;
        
    }

    .print_description_mobile{

        background-color:#b3a8ca;

        padding:1.5em 2em 1.5em 2em;

        color: white;

        line-height: 20px;

        max-height: 450px;
        overflow: auto; /* Activation du défilement vertical automatique */

        h3{
            font-weight: bolder;
        }

        span{

            font-weight: bolder;
            color:rgb(143, 4, 177);
        }

        ul{
            list-style: inside;

            li{
                span{

                    font-weight: bolder;
                    color:rgb(143, 4, 177);
                }
            }
            
        }

        a{
            
            color:rgb(177, 60, 207);
        }

    }

    
}



