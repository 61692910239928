

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 20px;
    left: 20px;
    top: 30px;
    
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
    
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    z-index: 30;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #b3a8ca;
    padding: 2.5em 1.5em 2.5em;
    font-size: 1.5em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #b3a8ca;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    color: white;
    line-height: 60px;
    animation: slide 0.6s ease-in-out;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

.active{

}
.sub-menu{
    font-size: 20px;
    margin-left:1.2em ;
    line-height: 40px;
    animation: slide 0.6s ease-in-out;
    
}

.sub-menu-item{
  animation: slide 1.5s ease-in-out;

  a{
            
    color:rgb(143, 4, 177);

    a:visited {
      color:rgb(143, 4, 177); /* Spécifie la couleur du texte pour les liens visités */
      /* Autres styles pour les liens visités */
    }
}

    
}

.selected_mobile{
    text-decoration: underline;
    color:rgb(143, 4, 177);
}


@keyframes slide {
    0% {
      transform: translateY(-10%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }




