.container-footer{
    width: 100%;
    display: flex;
    padding: 10px 0 10px 0;
    justify-content: center;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.483);
    margin-top: 3em;

    .container-footer-description{
        width: 50%;
        display: flex;
        justify-content: center;

        p{
            text-align: center;
            line-height: 25px;
        }
    }
}