.home_mobile{
    display: flex;
    justify-content: center;
    flex-direction: column;;
    overflow: hidden;


    


    .img_accueil_mobile{
        text-align: center;
        padding: 4em 0 2em 0;
        img{
            max-width: 100%;
        }
    }

    


    

    .card{
        display: flex;
        justify-content: center;
       
        padding: 1em 0 1em 0;

        .card_intro{
            position: relative; /* Pour permettre le positionnement absolu de l'image et du texte */
            width: 100%; /* Ajuster la largeur selon vos besoins */
            

            .title_intro{
                position: absolute; /* Positionnement absolu pour le titre */
                top: 50%; /* Positionner verticalement au milieu de la div parente */
                left: 50%; /* Positionner horizontalement au milieu de la div parente */
                transform: translate(-50%, -50%); /* Ajuster pour centrer parfaitement */
                text-align: center; /* Centrer le texte */
                color: #fff; /* Couleur du texte */
                font-size: 20px; /* Taille de police du titre */
                font-weight: bold; /* Gras du titre */
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Ombre du texte */
                z-index: 2;
            }

            .img_intro{
                
                width: 100%;
                height: auto;
                filter: blur(2px); /* Appliquer un flou à l'image */
                z-index: -5;
                object-position: bottom left;
            }
        }

        img{
            display: block;
            max-width: 100%;
            max-height: 200px;
            object-fit: cover;
        }

        
    }


    .categorie_see_mobile{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: auto;

        .categorie_link_mobile{
            color: black;
            text-align: center;
            margin: 0 0.2em 0 .2em;
            
        }

        .categorie_title_mobile{
            font-size: 16px; /* Taille de police du titre */
            font-weight: bold; /* Gras du titre */
            text-align: center;

            span{
                font-weight: bolder;
                color:rgb(143, 4, 177);
                
            }
        }

        .categorie_icon_mobile{
            margin-top: 15px;
            font-size: 1.6em;
            color: #b3a8ca;
        }
    }
}

.logo_mobile{
    text-align: center;
    padding-top: 2em;
    img{
       width: 25%;
       height: auto;
    }
    h1{
        margin-top: .2em;
        font-weight: bolder;
        font-size: 21px;
    }
    h2{
        margin-top: .5em;
        font-weight: bolder; 
    }

}

.title_mobile{
    text-align: center;
    text-decoration: underline;
    font-size: large;
    margin-top: 3em;
    margin-bottom: 2em;
    font-weight: bold;
}

.aboutme_mobile_container{
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;
}


.aboutme_mobile{
    

    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 90%;
    height: auto;
    text-align: center;
    align-items: center;
    padding: 0 1em 0 1em;
    img{
        width: 15em;
        height: auto;

        clip-path:ellipse(33% 50%);
        margin-top: 40px;


    }

    .aboutme_section_mobile{
        background-color:#b3a8ca;
        color: #fff;
        font-size: smaller;
        padding:1.2em 1.2em 1.2em 1.2em;
        margin-top: 2em;
        line-height: 15px;
        max-height: 250px; /* Hauteur maximale de la zone de texte */
        overflow-y: scroll; /* Activation du défilement vertical automatique */
        -webkit-overflow-scrolling: touch;
    }
}

// .hide{
//     overflow-y: hidden;
// }



/* Pour les navigateurs basés sur Webkit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
    width: 4px; /* Largeur de la barre de défilement */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #7d4882; /* Couleur du pouce de la barre de défilement */
    border-radius: 5px;
  }
  
  * {
    scrollbar-width: thin; /* Largeur de la barre de défilement */
    scrollbar-color: #7d4882; /* Couleur du pouce et de la barre de défilement */
  }
