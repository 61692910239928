.categorie-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  width: 90%;
  height: 100%;

  .subtitle_portfolio{
    margin: 1em 0 1.5em 100px;
    font-size: 30px;
    font-weight: bold;
  }
 

}

.subtitle_specific_title{
  padding-top:4em ;
}



.categorie{
  
  width: 100%;
  max-height: auto;
  display: flex;
  justify-content: center;
  background-color: rgba(179, 168, 202, 0.02);
  border-radius: 5px;
 
}
.card-wrapper{
  max-width: 100%;
  max-height: 600px;
  overflow:hidden;
  
  .card{
    max-width: 100%;
    height: auto;
    
    a{
      
      img{
        display: block;
        align-self: center;
        max-width: 100%;
        
        max-height:600px;
        /* Largeur maximale pour les images en mode paysage */
        object-fit: contain ;
        object-position: center bottom;
        transition: all 0.2s ease-out;
        
        cursor: pointer;

        &:hover{
          width: 100%;

          transform:scale(1.1);
          opacity: 0.87;

        }
      } 
    }

    

    
  }
}



