.logo{
    display: flex;
    flex-direction: row;
    
    img{
        width: 95px;
    }
    h1{
        width: 1em;
        margin-left:10px;
        padding-top: 38px;
        text-align: justify;
        font-weight: bold;
        font-size: 22px;
    }
}